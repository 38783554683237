@use "./../abstracts" as *;

$header-bg: map-get($colors, "white");

header {
  background-color: $header-bg;
  transition: transform 0.2s ease-in-out;

  &.scroll-up {
    transform: translateY(0);
  }

  &.scroll-down {
    transform: translateY(-100%);
  }

  > nav,
  > .mobile-nav {
    transition: transform 0.2s ease-in-out;
  }
}
