@use "./../../abstracts/colors" as *;
@use "./../../abstracts/mixins/buttons" as *;

// ########## Style
$color: map-get($colors, "fourth");
$bg: rgba(map-get($colors, "fourth"), 11%);
$b-color: map-get($colors, "fourth");

// ########## States
$hover-color: map-get($colors, "white");
$hover-bg: map-get($colors, "fourth");
$hover-b-color: map-get($colors, "fourth");

$active-color: map-get($colors, "white");
$active-bg: map-get($colors, "fourth");
$active-shadow: 0px 0px 28px -8px map-get($colors, "fourth");
$active-b-color: map-get($colors, "fourth");

// ########## Structure
.btn.btn-outline-tertiary {
  @include create-btn(
    $color: $color,
    $bg: $bg,
    $b-color: $b-color,
    $hover-color: $hover-color,
    $hover-bg: $hover-bg,
    $hover-b-color: $hover-b-color,
    $active-color: $active-color,
    $active-bg: $active-bg,
    $active-shadow: $active-shadow,
    $active-b-color: $active-b-color
  );
}
