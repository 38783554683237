@use "./../abstracts" as *;

$has-text-stroke-class: true;

// generate text color
@each $color, $value in $colors {
  .text-#{$color} {
    color: var(--#{$color}) !important;
  }
}

@if $has-text-stroke-class {
  @each $color, $value in $colors {
    // text stroke
    .text-stroke-#{$color} {
      --stroke-width: 1px;
      color: transparent !important;

      -webkit-text-stroke: var(--stroke-width) var(--#{$color}) !important;
    }
  }
}
