@use "./../abstracts/font/fw" as *;
@use "./../abstracts/colors" as *;

.result {
  &__check {
    display: inline-flex;
    align-items: center;
    gap: 0.75rem;
  }

  &__label {
    display: block;
    color: map-get($colors, "second");

    font: {
      weight: $bold;
      size: 1.875rem;
    }

    line-height: 1em;

    &--small {
      font-size: 0.8em;
    }
  }
}
