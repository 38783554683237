@use "./../abstracts/colors" as c;
@use "./../abstracts/font/fs" as fs;
@use "./../abstracts/mixins/breakpoints" as *;

$color: map-get(c.$colors, "white");
$bg: map-get(c.$colors, "first");

// Padding
$pt: 0.75rem;
$pb: 0.75rem;

// --- copyright
$copyright-pt: 1.5rem;

footer {
  --bg: #{$bg};
  background-color: $bg;
  color: $color;

  padding: {
    top: $pt;
    bottom: $pb;
  }
}

.copyright {
  // font-size: map-get(fs.$fs, 7);
  font-size: 13px;

  * {
    font-size: inherit;
  }

  &__list {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;

    @include breakpoint-min-sm {
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint-min-md {
      display: inline-flex;
      align-items: center;
    }

    list-style: none;
    padding: 0;
    margin: 0;

    a {
      font-weight: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
