@use "./../abstracts/mixins/breakpoints" as *;

// width custom
.w-custom {
  --width: 100%;
  width: var(--width) !important;
}

.mw-custom {
  --mw-width: unset;
  max-width: var(--mw-width) !important;
}

@each $media, $value in $media-queries {
  @media (min-width: $value) {
    .w-#{$media}-custom {
      --width-#{$media}: 100%;
      width: var(--width-#{#{$media}}) !important;
    }
  }
}
