@use "./../../abstracts/colors" as *;
@use "./../../abstracts/font/fw" as *;

$input-size: 1.25rem;
$input-radius: 2px;
$input-border: 1px solid rgba(map-get($colors, "first"), 60%);
$input_focus-border-color: map-get($colors, "first");
$input_checked-bg-color: map-get($colors, "first");
$input_checked-border-color: map-get($colors, "first");

$label-color: map-get($colors, "second");

.form-check {
  // display: flex;
  // align-items: center;
  // flex-wrap: nowrap;
  // gap: 13px;

  // Bootstrap Property Reset
  float: none;
  padding-left: 0;
  margin-bottom: 0;

  &-input {
    border: $input-border;
  }

  .form-check {
    &-input {
      width: $input-size;
      height: $input-size;

      border: {
        radius: $input-radius;
      }

      // Bootstrap Property Reset
      float: none;
      margin-top: 0;
      margin-left: 0;

      transition: 0.2s ease-in-out;

      &:focus {
        box-shadow: none;
        border-color: $input_focus-border-color;
      }

      &:checked {
        background-color: $input_checked-bg-color;
        border-color: $input_checked-border-color;
      }
    }

    &-label {
      color: $label-color;
      margin-left: 0.8125rem;
      font-weight: $regular;
    }
  }
}
