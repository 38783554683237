@use "./../abstracts/mixins/breakpoints" as *;

$gap: clamp(1.5rem, 0.65rem + 4.25vw, 5.75rem);

.grid {
  &-row {
    --grid-gap: #{$gap};

    display: grid;
    gap: var(--grid-gap);
    align-items: center;

    @include breakpoint-min-md {
      grid-template-columns: 1fr 1fr;

      &.grid--reverse {
        .grid-col:first-child {
          order: 1;
        }
      }

      &.grid--between {
        .grid-col {
          &:first-child {
            margin-left: auto;
          }

          &:last-child {
            margin-right: auto;
          }
        }
      }
    }
  }
}
