@use "./../../abstracts/mixins/buttons" as *;
@use "./../../abstracts/colors" as *;

// ########## Style
$color: map-get($colors, "third");

// ########## States
$hover-color: map-get($colors, "first");
$hover-bg: map-get($colors, "second");

$active-color: map-get($colors, "white");
$active-bg: map-get($colors, "first");
$active-shadow: 0px 0px 28px -8px map-get($colors, "first");

.link.primary-link {
  @include create-link($color: $color);
}
