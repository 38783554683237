[data-aos="fade-rotate"] {
  opacity: 0;
  transform: rotate(-20deg);
  transition-property: transform, opacity;
  transition-timing-function: ease-in-out;

  &.aos-animate {
    opacity: 1;
    transform: rotate(0);
  }
}
