$rounded: clamp(1rem, 0.8rem + 1vw, 2rem);

.img {
  &-square {
    aspect-ratio: 1;

    width: 100%;
    height: auto;

    object-fit: cover;
  }

  &-full {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  &--cover {
    max-width: 100%;
    object-fit: cover;
  }

  &--rounded {
    border-radius: $rounded;
  }

  &--ratio {
    --ratio: unset;
    aspect-ratio: var(--ratio);
  }
}

.object-custom-position {
  --position: center;

  object-position: var(--position) !important;
}
