@use './../abstracts' as *;

.nav {
	&bar {
		--bs-navbar-padding-y: 1rem;
		box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
	}

	&bar-nav {
		gap: 1.5rem;
	}

	&-link {
		color: var(--dark);
		font-weight: $semi-bold;
	}
}