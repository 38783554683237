@use "./../abstracts" as *;

// generate background colors
@each $color, $value in $colors {
  .bg-#{$color} {
    background-color: var(--#{$color}) !important;
    --bg: var(--#{$color});
  }
}

.bg-contain {
  background-size: contain !important;
}
