@use "./../../abstracts/font/fs" as fs;

$fs: map-get(fs.$fs, 6);
$lh: 1.5625em;

$big-fs: map-get(fs.$fs, 5);
$big-lh: 1.5em;

p {
  font-size: $fs;
  line-height: $lh;

  &.big {
    font-size: $big-fs;
    line-height: $big-lh;
  }
}
