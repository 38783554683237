@use "./../../abstracts/font/fs" as fs;

$fs: #{map-get(fs.$fs, 7)};
$background-image: null;

$svgPath: url(./../img/ul-check.svg);

ul {
  font-size: $fs;

  &.marker--checker {
    list-style: none;
    padding-left: 0;

    li {
      padding-left: 1.875rem; // image width + space

      background-image: $background-image;

      @if $background-image {
        background: {
          position: center left;
          repeat: no-repeat;
        }
      }

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }

  &.marker--svg {
    list-style: $svgPath;

    > li {
      padding-left: 1.25rem;
      margin-bottom: 1.25rem;
    }
  }

  &.marker--bar {
    list-style: "–";

    > li {
      padding-left: 0.25rem;
    }
  }

  // Size
  &.list {
    &--md {
      li {
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
