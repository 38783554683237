@use "sass:map";

// Bootstrap Breakpoint
$media-queries: (
	"sm": 576px,
	"md": 768px,
	"lg": 992px,
	"xl": 1200px,
	"xxl": 1400px,
);

// min-width
@mixin breakpoint-min-sm {
	@media (min-width: map.get($media-queries, "sm")) {
		@content;
	}
}

@mixin breakpoint-min-md {
	@media (min-width: map.get($media-queries, "md")) {
		@content;
	}
}

@mixin breakpoint-min-lg {
	@media (min-width: map.get($media-queries, "lg")) {
		@content;
	}
}

@mixin breakpoint-min-xl {
	@media (min-width: map.get($media-queries, "xl")) {
		@content;
	}
}

@mixin breakpoint-min-xxl {
	@media (min-width: map.get($media-queries, "xxl")) {
		@content;
	}
}

// max-width
@mixin breakpoint-max-sm {
	@media (max-width: calc(map.get($media-queries, "sm") - 1px)) {
		@content;
	}
}

@mixin breakpoint-max-md {
	@media (max-width: calc(map.get($media-queries, "md") - 1px)) {
		@content;
	}
}

@mixin breakpoint-max-lg {
	@media (max-width: calc(map.get($media-queries, "lg") - 1px)) {
		@content;
	}
}

@mixin breakpoint-max-xl {
	@media (max-width: calc(map.get($media-queries, "xl") - 1px)) {
		@content;
	}
}

@mixin breakpoint-max-xxl {
	@media (max-width: calc(map.get($media-queries, "xxl") - 1px)) {
		@content;
	}
}
