@use "./../../abstracts/colors" as *;
@use "./../../abstracts/font/fw" as *;

$color: map-get($colors, "first");

$lh: 1.1666666666666667em;
$fw: $regular;

$mb: 0.5rem;

.form-label {
  color: $color;
  margin-bottom: $mb;

  line-height: $lh;

  font: {
    weight: $fw;
  }
}
