@use "./../../abstracts/colors" as c;
@use "./../../abstracts/font" as *;
@use "./../../abstracts/mixins/buttons" as *;

$color: map-get(c.$colors, "second");
$bg: transparent;

$min-h: 5.5625rem;

$gap: 1.25rem;

$pr: 2.6875rem;
$pl: 2.6875rem;

$fs: clamp(1.375rem, 1.275rem + 0.5vw, 1.875rem);
$fw: $bold;

$b-color: map-get(c.$colors, "second");
$b-radius: 1.5rem;

$hover-color: map-get(c.$colors, "white");
$hover-bg: map-get(c.$colors, "second");

.btn.btn-value {
  @include create-btn(
    $color: $color,
    $bg: $bg,

    $gap: $gap,

    $pr: $pr,
    $pl: $pl,

    $min-height: $min-h,
    $b-radius: $b-radius,
    $b-color: $b-color,

    $fs: $fs,
    $fw: $fw,

    $hover-color: $hover-color,
    $hover-bg: $hover-bg
  );

  &:hover {
    * {
      transform: inherit;
      fill: white;
    }
  }
}
