@use "./../abstracts/colors" as *;
@use "./../abstracts/mixins/breakpoints" as *;

// --- BG Color
$bg: map-get($colors, "first");

// --- BG Image
$bg-size: 110%;
$bg-repeat: no-repeat;
$bg-position: 20vw;

// --- BG After
$bgAfter: null;
$bgAfterBlur: 5px;

.hero {
  --hero-img: unset;

  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;

  padding-block: 5vh;

  min-height: 100svh;

  overflow: hidden;

  background: {
    color: $bg;
    image: var(--hero-img);
    position: $bg-position;
    size: $bg-size;
    repeat: $bg-repeat;
    attachment: none;
  }

  @include breakpoint-min-lg {
    justify-content: center;
  }

  @if $bgAfter {
    &:after {
      content: "";
      position: absolute;
      inset: 0;
      z-index: -1;

      backdrop-filter: blur(#{$bgAfterBlur});

      background-color: $bgAfter;
    }
  }
}
