@use "./../abstracts" as *;
@use "./../abstracts/font/fw" as *;
@use "./../abstracts/font/fs" as *;

$pt: 1.5rem;
$pb: 1.5rem;

$link-fw: 0.875rem;

$logo-mw: 214px;

.navbar {
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.2);

  padding: {
    top: $pt;
    bottom: $pb;
  }

  &-nav {
    column-gap: 1.4375rem;
  }

  &-logo {
    max-width: $logo-mw;

    width: 50%;
    height: auto;
  }
}

.nav {
  &-link {
    padding: 0 !important;

    font: {
      size: $link-fw;
      weight: $regular;
    }

    &.active {
      font-weight: $bold;
    }
  }
}
