@use './../abstracts' as *;

.badge {
	--badge-padding-block: 0.4rem;
	--badge-padding-inline: 3rem;

	--bs-badge-color: var(--dark);

	font-size: 1rem;
	font-weight: $regular;

	text-transform: uppercase;

	padding: 0;
	margin-block-end: 1.8rem;

	padding-block: var(--badge-padding-block);
	padding-inline-end: var(--badge-padding-inline);

	border-bottom: 1px solid var(--dark);
	border-radius: 0;

	&.badge-center {
		padding-inline: calc(var(--badge-padding-inline) / 2);
	}

	&.badge-right {
		padding-inline-start: var(--badge-padding-inline);
		padding-inline-end: 0;
	}
}