@use './../abstracts' as *;

.masonry-grid {
	&.active {
		.masonry-grid-item {
			&:nth-child(n + 7) {
				position: relative;

				height: 100%;
				opacity: 1;
				scale: 1;
			}
		}
	}

	display: grid;

	gap: 1rem;
	grid-template-columns: 1fr;
	grid-auto-rows: 3rem;

	@include breakpoint-min-md {
		gap: 2rem;

		grid-template-columns: repeat(2, 1fr);
		grid-auto-rows: 1rem;
	}

	@include breakpoint-min-xl {
		grid-template-columns: repeat(3, 1fr);
	}

	&-item {
		position: relative;

		grid-row: span 4;

		@include breakpoint-min-md {
			&:nth-child(2) {
				padding-block-start: 3rem;
			}

			&:nth-child(even) {
				grid-row: span 8;
			}

			&:nth-child(odd) {
				grid-row: span 11;
			}

			&:nth-child(4n-10) {
				grid-row: span 16;
			}
		}

		&:nth-child(n + 7) {
			position: absolute;

			height: 0px;
			overflow: hidden;
			opacity: 0;
			scale: 0.8;

			transition: all 0.2s ease-in-out;
		}

		// da spostare
		.card {
			box-shadow: unset;

			background-color: unset;

			position: relative;

			height: 100%;

			display: grid;
			place-items: center;

			padding: 1rem;

			border: 1px solid #00000015;
			border-radius: 0;

			&-body {
				flex: unset;
				padding: unset;

				opacity: 0;
				transform: translateY(2rem);

				transition: all 0.2s ease-in-out;
			}

			&-footer {
				position: absolute;
				bottom: 0;
				left: 0;

				width: 100%;

				color: var(--white);

				padding: 1rem 2rem;

				border: unset;

				background-color: unset;

				z-index: 0;

				>* {
					z-index: 1;
					font-size: 1.3rem;
				}

				&:after {
					content: "";

					position: absolute;
					bottom: 0;
					left: 0;

					width: 100%;
					height: 150%;

					background: linear-gradient(180deg,
							rgba(48, 48, 48, 0) 20%,
							rgba(48, 48, 48, 0.5) 100%);

					z-index: -1;
				}
			}

			&-image {
				position: absolute;
				top: 0;
				left: 0;

				z-index: -2;

				width: 100%;
				height: 100%;

				object-fit: cover;
				object-position: center;
			}

			&:hover {
				.card {
					&-body {
						opacity: 1;
						transform: translateY(0);
					}
				}
			}
		}
	}
}