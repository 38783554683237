@use "./../abstracts/mixins/breakpoints" as *;

.grid-benefits {
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;
  justify-content: space-between;

  @include breakpoint-min-lg {
    grid-template-columns: repeat(3, 1fr);
  }
}
