@use "./../../abstracts/font/fw" as *;
@use "./../../abstracts/colors" as *;
@use "./control" as control;

$after-mh: control.$mh;

.form {
  &__group {
    position: relative;
  }

  &--after {
    .form-control {
      padding-right: 3.25rem;
    }

    .is-invalid {
      background-position: right calc(3.375em + 0.1875rem) center;

      &.form-control {
        padding-right: 5.25rem;
      }

      /* + .form__after {
        color: #{var(--bs-form-invalid-color)};
      } */
    }

    &.is-invalid {
    }
  }

  &__after {
    color: map-get($colors, "second");

    pointer-events: none;

    position: absolute;
    top: 0;
    bottom: 0;
    right: 0.875rem;

    max-height: $after-mh;

    display: flex;
    flex-direction: column;
    justify-content: center;

    font-weight: $regular;

    opacity: 0.6;

    transition: 0.2s ease-in-out;
  }
}
