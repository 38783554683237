@use "./../abstracts" as *;

$bg: null;
// Custom Properties in _root.scss

section {
  // Section Spacing Custom Properties
  --spacing: clamp(2.8125rem, 2.0982rem + 3.5714vw, 5.3125rem);
  --spacing-rounded: clamp(1.875rem, 1.35rem + 2.625vw, 4.5rem);

  background-color: $bg;
  margin-block: var(--spacing);

  position: relative;
  overflow-y: hidden;

  &:first-of-type {
    margin-block-start: 0;
  }

  &.section--inner {
    margin-block: 0;
    padding-block: var(--spacing);

    &-small {
      --spacing: clamp(1.25rem, 0.8929rem + 1.7857vw, 2.5rem);
    }
  }

  &.section--canvas {
    --canvas-index: 0;
    z-index: var(--canvas-index);
  }

  &.section--rounded {
    border-bottom: {
      right-radius: var(--spacing-rounded);
      left-radius: var(--spacing-rounded);
    }

    + .section--inner {
      &:before {
        content: "";

        background-color: var(--bg);

        position: absolute;
        top: calc(calc(var(--spacing-rounded) * -1) + 1px);

        width: 100%;
        height: var(--spacing-rounded);

        pointer-events: none;
        z-index: -1;
      }
    }
  }

  &.section--container-gap {
    display: flex;
    flex-direction: column;
    gap: var(--spacing);
  }

  &.section--collapse {
    margin-block-start: calc(calc(var(--spacing-rounded) * -1));
    margin-block-end: 0;

    z-index: 2;

    + .section--inner {
      &:before {
        content: "";

        background-color: var(--bg);

        position: absolute;
        top: calc(-1000vh + 1px);

        width: 100%;
        height: 1000vh;

        pointer-events: none;
        z-index: -3;
      }
      // margin-block-start: calc(var(--spacing) * -1);
      // padding-block-start: calc(calc(var(--spacing) * 2) + 142px);
    }
  }

  .section__title {
    margin-bottom: clamp(1.875rem, 1rem + 4.375vw, 6.25rem);
  }
}

// Deprecated
// section {
// 	--section-py: 5rem;

// 	@include breakpoint-min-md {
// 		--section-py: 9.375rem;
// 	}

// 	--section-p-end-last-type: 3.125rem;
// 	--section-margin: 0.5;
// 	position: relative;

// 	&.section-inner {
// 		padding-block: unset;

// 		>*:first-child {
// 			padding-block: var(--section-py);
// 		}
// 	}

// 	&.section-cut {
// 		@include breakpoint-min-xl {
// 			.section-container {
// 				max-width: 95%;
// 			}
// 		}
// 	}

// 	&.section-lg-unset {
// 		@include breakpoint-max-lg {
// 			.section-container {
// 				padding-block-start: 0;
// 			}
// 		}
// 	}

// 	&.section-sm {
// 		--section-py: 2.8rem;

// 		@include breakpoint-min-md {
// 			--section-py: 3.8rem;
// 		}
// 	}

// 	&.section-md {
// 		--section-py: 5rem;

// 		@include breakpoint-min-md {
// 			--section-py: 7.5rem;
// 		}
// 	}

// 	&.section-lg {
// 		--section-py: 8rem;

// 		@include breakpoint-min-md {
// 			--section-py: 16rem;
// 		}

// 		@include breakpoint-min-lg {
// 			--section-py: 12.5rem;
// 		}
// 	}

// 	&:last-of-type {
// 		.section-container {
// 			padding-block-end: var(--section-p-end-last-type);
// 		}
// 	}

// 	.section-box {
// 		--section-box-p-block: 3rem;
// 		--section-box-p-inline: 1.5rem;

// 		border-radius: 1.5rem;
// 		overflow: hidden;

// 		padding: var(--section-box-p-block) var(--section-box-p-inline);

// 		@include breakpoint-min-md {
// 			--section-box-p-block: 3rem;
// 			--section-box-p-inline: 3rem;
// 		}

// 		@include breakpoint-min-lg {
// 			--section-box-p-block: 4rem;
// 			--section-box-p-inline: 3rem;
// 		}

// 		@include breakpoint-min-xl {
// 			--section-box-p-block: 6rem;
// 			--section-box-p-inline: 3.5rem;
// 		}

// 		@include breakpoint-min-xxl {
// 			--section-box-p-block: 7.75rem;
// 			--section-box-p-inline: 6.5rem;
// 		}
// 	}

// 	.section-container {
// 		position: relative;

// 		// overflow-x: hidden;

// 		margin: auto;
// 		padding-block: calc(var(--section-py));

// 		.section-bg {
// 			position: absolute;
// 			top: 0;
// 			left: 0;

// 			width: 100%;
// 			height: 100%;

// 			z-index: -1;

// 			img {
// 				width: 100%;
// 				height: 100%;

// 				object-fit: cover;
// 				object-position: center;
// 			}

// 			&.section-bg-filter {
// 				&:after {
// 					content: "";

// 					position: absolute;
// 					top: 0;
// 					left: 0;

// 					width: 100%;
// 					height: 100%;

// 					background-color: var(--primary);

// 					z-index: 1;
// 				}

// 				&.multiply {
// 					img {
// 						filter: grayscale(1);
// 					}

// 					&:after {
// 						mix-blend-mode: multiply;
// 					}
// 				}
// 			}

// 			.section-bg-filter-hover {
// 				.section-bg-filter {

// 					img,
// 					&:after {
// 						transition: all 0.4s ease-in-out;
// 					}

// 					img {
// 						filter: grayscale(0);
// 					}

// 					&:after {
// 						opacity: 0;
// 					}
// 				}

// 				&:hover {
// 					.section-bg-filter {
// 						img {
// 							filter: grayscale(1);
// 						}

// 						&:after {
// 							opacity: 1;
// 						}
// 					}
// 				}
// 			}
// 		}

// 		>[class*="container"]+[class*="container"] {
// 			margin-top: 3rem;

// 			@include breakpoint-min-md {
// 				margin-top: 4rem;
// 			}

// 			@include breakpoint-min-lg {
// 				margin-top: 6rem;
// 			}

// 			@include breakpoint-min-xl {
// 				margin-top: 7rem;
// 			}

// 			@include breakpoint-min-xxl {
// 				margin-top: 9rem;
// 			}
// 		}
// 	}
// }
