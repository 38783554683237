@use "./../abstracts/colors" as *;
@use "./../abstracts/mixins/breakpoints" as *;
@use "./map" as *;

.google {
  position: relative;

  &__search {
    width: 100%;
    max-width: calc(100% - 2rem);

    // min-height: 16.25rem;

    position: absolute;
    top: 1rem;
    left: 1rem;

    z-index: 1;

    @include breakpoint-min-lg {
      max-width: 382px;

      top: $general-spacing-y;
      left: $general-spacing-x;
    }
  }

  &__search {
  }

  &__input {
    &.form-control {
      border-radius: 0.25rem;
      padding-left: 1rem;
      padding-right: 98px;
    }

    &:focus {
      + .google__icon {
        opacity: 1;
      }
    }
  }

  &__icon {
    position: absolute;

    width: 18px;
    height: 100%;

    object-fit: contain;
    object-position: center;

    top: 0;
    bottom: 0;
    right: 72px;

    opacity: 0.7;

    transition: opacity 0.2s ease-in-out;
  }

  &__current-location {
    position: absolute;

    right: 2px;
    top: 2px;
    bottom: 0;

    height: calc(100% - 4px);

    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;

    display: grid;
    place-items: center;

    min-width: 52px;

    border: 0;
    background-color: map-get($colors, "third");

    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: darken(map-get($colors, "third"), 8%);
    }
  }
}
