@use "./../font" as *;

// Mixin Btn
@mixin create-btn(
  // Layout
  $flex: null,
  $gap: null,
  $min-width: null,
  $min-height: null,
  // Theme
  $color: null,
  $bg: null,
  $shadow: null,
  // Font
  $ff: null,
  $fs: null,
  $fw: null,
  // Padding
  $pt: null,
  $pr: null,
  $pb: null,
  $pl: null,
  // Border
  $b-color: null,
  $b-size: null,
  $b-radius: null,
  // # State: Hover
  $hover-color: null,
  $hover-bg: null,
  $hover-shadow: null,
  $hover-b-color: null,
  // # State: Active
  $active-color: null,
  $active-bg: null,
  $active-shadow: null,
  $active-b-color: null,
  // # State: Focus
  $focus-color: null,
  $focus-bg: null,
  $focus-shadow: null,
  $focus-b-color: null
) {
  @if $flex {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: $gap;
  }

  color: $color;
  background-color: $bg;
  box-shadow: $shadow;

  min-height: $min-height;
  min-width: $min-width;

  transition: all 0.2s ease-in-out;

  padding: {
    top: $pt;
    right: $pr;
    bottom: $pb;
    left: $pl;
  }

  border: {
    color: $b-color;
    width: $b-size;
    radius: $b-radius;
  }

  font: {
    family: $ff;
    size: $fs;
    weight: $fw;
  }

  &:hover {
    color: $hover-color;
    background-color: $hover-bg;
    box-shadow: $hover-shadow;
    border-color: $hover-b-color;
  }

  &:focus {
    color: $focus-color;
    background-color: $focus-bg;
    box-shadow: $focus-shadow;
    border-color: $focus-b-color;
  }

  &:active {
    color: $active-color;
    background-color: $active-bg;
    box-shadow: $active-shadow;
    border-color: $active-b-color;
  }
}

// Mixin Link
@mixin create-link(
  // Theme
  $color: null,

  // Layout
  $gap: null,
  $min-width: null,
  $min-height: null,

  // Font
  $ff: null,
  $fs: null,
  $fw: null,
  $td: null,

  // # State: Hover
  $hover-color: null,
  $hover-bg: null,
  $hover-shadow: null,
  $hover-b-color: null,

  // # State: Active
  $active-color: null,
  $active-bg: null,
  $active-shadow: null,
  $active-b-color: null,

  // # State: Focus
  $focus-color: null,
  $focus-bg: null,
  $focus-shadow: null,
  $focus-b-color: null
) {
  color: $color;
  text-decoration: none;

  > span {
    text-decoration: $td;
  }

  font: {
    size: $fs;
    family: $ff;
    weight: $fw;
  }

  display: inline-flex;
  align-items: center;
  gap: $gap;

  &:hover {
    color: $hover-color;
    background-color: $hover-bg;
    box-shadow: $hover-shadow;
    border-color: $hover-b-color;
  }

  &:focus {
    color: $focus-color;
    background-color: $focus-bg;
    box-shadow: $focus-shadow;
    border-color: $focus-b-color;
  }

  &:active {
    color: $active-color;
    background-color: $active-bg;
    box-shadow: $active-shadow;
    border-color: $active-b-color;
  }
}
