@use "./../abstracts/font/fw" as *;
@use "./../abstracts/colors" as *;

$progress-size: 140px;
$progress-bar-min-size: 2.875rem;

.progress {
  &-circle {
    &-container {
      --circle-progress: 0%;

      display: grid;
      place-items: center;

      color: map-get($colors, "second");

      width: $progress-size;
      height: $progress-size;

      font: {
        size: 1.875rem;
        weight: $bold;
      }

      > * {
        grid-area: 1/1;
      }
    }

    width: 100%;
    height: 100%;

    border-radius: 50%;
    background: radial-gradient(closest-side, white 75%, transparent 74% 100%),
      conic-gradient(
        map-get($colors, "third") var(--circle-progress),
        map-get($colors, "first") 0
      );

    -webkit-mask:
      url("./../img/mask-image.svg") center/contain no-repeat,
      linear-gradient(#fff 0 0);

    -webkit-mask-repeat: no-repeat;

    mask-size: 100%;
    -webkit-mask-size: 100%;

    mask-position: center;
    -webkit-mask-position: center;

    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
  }

  // Progress bar
  &__bar {
    --width: unset;

    min-width: none;
    min-height: $progress-bar-min-size;

    display: grid;
    grid-template-columns: 1fr;

    border-radius: 2rem;

    &.less-30 {
      grid-template-columns: minmax($progress-bar-min-size, var(--width)) auto auto;

      .progress {
        &__line {
          width: unset;
        }

        &__labels {
          color: map-get($colors, "second");
          padding-left: 1rem;
        }
      }
    }

    &.line--2{
      background: #D0D9DA;
    }

    &:not(.less-30) {
      > * {
        grid-area: 1/1;
      }
    }

    &:last-child {
      margin-top: 1rem;
    }

    // Progress Colors
    &.line {
      &--1 {
        --progress-color: #{map-get($colors, "white")};
        --progress-bg: #{map-get($colors, "first")};
      }

      &--2 {
        --progress-color: #{map-get($colors, "second")};
        --progress-bg: #{linear-gradient(
            90deg,
            rgba(220, 219, 63, 1) 0%,
            rgba(220, 187, 63, 1) 100%
          )};
      }
    }

    .progress {
      &__labels {
        display: flex;
        flex-direction: column;
        justify-content: center;

        padding-left: 1.5rem;
        padding-right: 0.5rem;

        color: var(--progress-color);

        &--difference{
          width: fit-content;
          margin-left: auto;
          padding-left: 0.5rem;
          padding-right: 1.5rem;
          position: relative;
          background: rgba(#D0D9DA, .6);
          border-top-right-radius: 2rem;
          border-bottom-right-radius: 2rem;

          &::before{
            content:"";
            position: absolute;
            left: -1rem;
            top: 0;
            display: block;
            width: 1rem;
            height: 100%;
            background: linear-gradient(90deg, rgba(208,217,218,0) 0%, rgba(208,217,218,.6) 100%);
          }


        }
      }

      &__line {
        width: var(--width);
        min-width: $progress-bar-min-size;
        height: 100%;

        border-radius: 2rem;
        line-height: 1;

        background: var(--progress-bg);
      }

      &__title {
        line-height: 1;

        font: {
          size: 1.125rem;
          weight: $bold;
        }
      }

      &__subtitle {
        line-height: 1;
        margin-bottom: 0.25rem;

        font: {
          size: 0.875rem;
          weight: $regular;
        }
      }
    }
  }
}
