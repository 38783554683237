// Create Spacing
// Spacing
$prefix: "spacing-";
$sizing: 4;

@mixin finite-spacing-utils($i: 0, $bp: "") {
  .#{$prefix}p-#{$i}#{$bp} {
    padding: #{calc($sizing * $i) + px} !important;
  }

  .#{$prefix}pt-#{$i}#{$bp} {
    padding-top: #{calc($sizing * $i) + px} !important;
  }

  .#{$prefix}pr-#{$i}#{$bp} {
    padding-right: #{calc($sizing * $i) + px} !important;
  }

  .#{$prefix}pb-#{$i}#{$bp} {
    padding-bottom: #{calc($sizing * $i) + px} !important;
  }

  .#{$prefix}pl-#{$i}#{$bp} {
    padding-left: #{calc($sizing * $i) + px} !important;
  }

  .#{$prefix}px-#{$i}#{$bp} {
    padding-right: #{calc($sizing * $i) + px} !important;
    padding-left: #{calc($sizing * $i) + px} !important;
  }

  .#{$prefix}py-#{$i}#{$bp} {
    padding-top: #{calc($sizing * $i) + px} !important;
    padding-bottom: #{calc($sizing * $i) + px} !important;
  }

  .#{$prefix}m-#{$i}#{$bp} {
    margin: #{calc($sizing * $i) + px} !important;
  }

  .#{$prefix}mt-#{$i}#{$bp} {
    margin-top: #{calc($sizing * $i) + px} !important;
  }

  .#{$prefix}mr-#{$i}#{$bp} {
    margin-right: #{calc($sizing * $i) + px} !important;
  }

  .#{$prefix}mb-#{$i}#{$bp} {
    margin-bottom: #{calc($sizing * $i) + px} !important;
  }

  .#{$prefix}ml-#{$i}#{$bp} {
    margin-left: #{calc($sizing * $i) + px} !important;
  }

  .#{$prefix}mx-#{$i}#{$bp} {
    margin-right: #{calc($sizing * $i) + px} !important;
    margin-left: #{calc($sizing * $i) + px} !important;
  }

  .#{$prefix}my-#{$i}#{$bp} {
    margin-top: #{calc($sizing * $i) + px} !important;
    margin-bottom: #{calc($sizing * $i) + px} !important;
  }

  .#{$prefix}gap-#{$i}#{$bp} {
    gap: #{calc($sizing * $i) + px} !important;
  }
}
