@use "./../abstracts/mixins/breakpoints" as *;
@use "./../abstracts/colors" as *;

// --- Card
// Padding
$pt: clamp(1rem, 0.7rem + 1.5vw, 2.5rem);
$pr: clamp(1.25rem, 1.1rem + 0.75vw, 2rem);
$pb: clamp(1rem, 0.8rem + 1vw, 2rem);
$pl: clamp(1.25rem, 1.1rem + 0.75vw, 2rem);

// Border
$b-width: 1px;

// --- Rounded
$rounded: 1.5rem;
$rounded-md: 2rem;

// --- Card Theme First
$primary-color: map-get($colors, "white");
$primary-bg: transparent;
$primary-b-color: map-get($colors, "white");

$primary-hover-color: map-get($colors, "second");
$primary-hover-bg: map-get($colors, "first");
$primary-hover-b-color: map-get($colors, "first");

// --- Card BG
$card-bg: false;

// --- Card Big
$big-px: clamp(1rem, -0.3rem + 6.5vw, 7.5rem);
$big-py: clamp(1.5rem, 1rem + 2.5vw, 4rem);
$big-radius: clamp(1.5rem, 1.4rem + 0.5vw, 2rem);

.card {
  overflow: hidden;
  position: relative;
  z-index: 0;

  height: 100%;

  border-width: $b-width;

  padding: $pt $pr $pb $pl;

  &,
  .card__icon svg path {
    transition: 0.4s ease-in-out;
  }

  &--min-h {
    &-1,
    &-2 {
      min-height: 250px;

      display: grid;
      place-items: center;
    }

    @include breakpoint-min-md {
      &-1 {
        min-height: 325px;
      }

      &-2 {
        min-height: 285px;
      }
    }
  }

  &--rounded {
    border-radius: $rounded;

    &-md {
      border-radius: $rounded-md;
    }
  }

  // Size
  &--big {
    border-radius: $big-radius;

    padding: {
      inline: $big-px;
      block: $big-py;
    }
  }

  // Card Theme
  &--theme-primary {
    color: $primary-color;
    background-color: $primary-bg;
    border-color: $primary-b-color;

    &:hover {
      color: $primary-hover-color;
      background-color: $primary-hover-bg;
      border-color: $primary-hover-b-color;

      box-shadow: 0 0 20px rgba(map-get($colors, "black"), 20%);

      path {
        fill: $primary-hover-color;
      }
    }
  }

  // Card BG
  @if $card-bg {
    .card__bg {
      --card-bg-width: 40%;
      --card-bg-top: 0;
      --card-bg-right: unset;
      --card-bg-left: unset;
      --card-bg-transform: unset;

      width: var(--card-bg-width);
      height: 100%;

      position: absolute;
      z-index: -1;

      top: var(--card-bg-top);
      right: var(--card-bg-right);
      left: var(--card-bg-left);

      img {
        object-fit: contain;
        height: 100%;
        width: 100%;

        transform: scale(var(--card-bg-transform));
      }

      &.card__bg--1 {
        --card-bg-right: -10%;
        --card-bg-transform: 1.2;
      }

      &.card__bg--2 {
        --card-bg-top: -10%;
        --card-bg-right: -15%;
      }

      &.card__bg--3 {
        --card-bg-right: -12%;
        --card-bg-transform: 1.2;
      }

      &.card__bg--4 {
        --card-bg-right: -10%;
        --card-bg-transform: 1.3;
        --card-bg-width: 30%;
      }

      &.card__bg--5 {
        --card-bg-right: -10%;
        --card-bg-transform: 1.4;
      }

      &.card__bg--6 {
        --card-bg-width: 90%;
        --card-bg-top: -20%;
        --card-bg-left: -20%;
        --card-bg-transform: 1.7;
      }

      &.card__bg--7 {
        --card-bg-width: 50%;
        --card-bg-top: -20%;
        --card-bg-right: -10%;
        --card-bg-transform: 1.7;
      }

      &.card__bg--8 {
        --card-bg-width: 70%;
        --card-bg-right: -5%;
        --card-bg-transform: 1.2;
        --card-bg-top: 15%;
      }
    }
  }
}
