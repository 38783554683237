@use "./../../abstracts/mixins/buttons" as *;
@use "./../../abstracts/font" as *;

// Font
// $fs: map-get($fs, 6); Non funziona
$ff: $ff-1;
$fs: clamp(1.375rem, 1.35rem + 0.125vw, 1.5rem);
$fw: $bold;
$lh: 1.2916666666666667em;

// Text Decoration
$td: underline;

$gap: 0.25rem;

.link {
  @include create-link($td: $td, $ff: $ff, $fs: $fs, $fw: $fw, $gap: $gap);

  line-height: $lh;

  > span {
    text-underline-offset: 0.1875rem;
  }

  > * {
    font-weight: inherit !important;
  }
}
