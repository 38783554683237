// @use "./../abstracts" as *;
@use "./../abstracts" as *;

$body-ff: $ff-1;
$body-bg: map-get($colors, "fifth");
$body-color: map-get($colors, "fourth");
$body-fw: $light;
$body-overflow: null;

$small-fs: map-get($fs, 9);
$small-fw: null;

body {
  color: $body-color;
  background-color: $body-bg;

  font-weight: $body-fw;

  overflow-x: $body-overflow;

  font: {
    family: $body-ff;
  }
}

textarea {
  resize: none;

  &::placeholder {
    font-style: italic;
    font-weight: $light;
    color: #00000040;
  }
}

p:last-of-type {
  margin-bottom: 0;
}

hr {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border-color: #b4c0c5;
}

b,
strong {
  font-weight: $bold;
}

video {
  max-width: 100%;
  height: auto;
  display: block;
}

small {
  font: {
    size: $small-fs;
    weight: $small-fw;
  }
}

a {
  color: var(--primary);
  font-weight: $semi-bold;

  &:hover {
    color: var(--primary-dark);
  }
}

// Reset Bootstrap Heading
@for $i from 1 through 6 {
  h#{$i},
  .h#{$i} {
    color: inherit;
  }
}
